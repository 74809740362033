import React, { Fragment } from 'react';

import type { UpdatableAttributes } from '.';
import type { TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';

import { Field, FieldError, Label, Radio } from 'components';

import MoneyInput from 'scenes/admin/components/MoneyInput';

type Props = {
  session: TrainingSession;
  errors: FormErrors;
  disabled: boolean;
  onChange: (attributes: Partial<UpdatableAttributes>) => void;
};

const EditableFields = ({ session, errors, disabled, onChange }: Props) => {
  return (
    <Fragment>
      <Field>
        <Label>
          {__('Is the training cost billed per session or per participant?')}
        </Label>
        <div className="flex -ml-3">
          <Radio
            label={__('Per session')}
            isChecked={session.costComputationType === 'per_session'}
            disabled={disabled}
            onChange={() =>
              onChange({
                costComputationType: 'per_session',
              })
            }
          />
          <Radio
            label={__('Per participant')}
            isChecked={session.costComputationType === 'per_participant'}
            onChange={() =>
              onChange({
                costComputationType: 'per_participant',
              })
            }
            disabled={disabled}
          />
        </div>
        <FieldError>{errors.costComputationType}</FieldError>
      </Field>
      <Field>
        <Label>{__('Session total cost')}</Label>
        <MoneyInput
          amount={session.totalBudgetCents}
          placeholder={__('Enter an amount')}
          onAmountChange={totalBudgetCents => {
            onChange({
              totalBudgetCents: totalBudgetCents || undefined,
            });
          }}
          currency={session.totalBudgetCurrency}
          isDisabled={disabled}
          saveOnlyOnBlur
          additionalClassName="max-w-[50%]"
        />
        <FieldError>{errors.totalBudget}</FieldError>
      </Field>
      <Field>
        <Label>{__('Will funding be the same for all participants?')}</Label>
        <div className="flex -ml-3">
          <Radio
            label={__('Yes')}
            isChecked={session.sameFundingPerParticipant}
            disabled={disabled}
            onChange={() =>
              onChange({
                sameFundingPerParticipant: true,
              })
            }
          />
          <Radio
            label={__('No')}
            isChecked={!session.sameFundingPerParticipant}
            onChange={() =>
              onChange({
                sameFundingPerParticipant: false,
              })
            }
            disabled={disabled}
          />
        </div>
        <FieldError>{errors.costComputationType}</FieldError>
      </Field>
    </Fragment>
  );
};

export default EditableFields;
