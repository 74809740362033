import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { formatMoney } from 'helpers/money';

import { put } from 'redux/actions/api';

import {
  Box,
  Notification,
  PullRight,
  SavingStatusLabel,
  StrictlySanitizedHtml,
  Text,
  WithSavingStatusRecorder,
} from 'components';

import EditableFields from './EditableFields';
import SessionFundingSection from './SessionFundingSection';

type Props = { session: TrainingSession };

export type UpdatableAttributes = {
  costComputationType: TrainingSession['costComputationType'];
  sameFundingPerParticipant: boolean;
  totalBudgetCents: number;
  fundingItemsAttributes: Array<{}>;
};

const Cost = ({ session }: Props) => {
  const [errors, setErrors] = React.useState<FormErrors>({});
  const dispatch = useAppDispatch();

  const persistSessionChanges = async (
    attributes: Partial<UpdatableAttributes>
  ) => {
    setErrors({});
    await handleFormErrors(
      () =>
        dispatch(
          put(`training/sessions/${session.id}`, {
            trainingSession: {
              ...attributes,
            },
          })
        ),
      setErrors,
      true
    );
  };
  const cannotUpdateSession = !can({ perform: 'update', on: session });
  const warningMessage = (() => {
    if (session.costComputationType === 'per_session') {
      if (!session.totalBudgetCents) return null;

      if (session.sameFundingPerParticipant) {
        invariant(session.fundingItems, 'fundingItems is required');
        if (session.fundingItems.length === 0) return null;

        const totalFundingCents = session.fundingItems.reduce(
          (total, item) => total + (item.amountCents || 0),
          0
        );
        if (session.totalBudgetCents !== totalFundingCents) {
          return __(
            'Please note that the sum of the configured funding <b>does not equal the total session cost</b>, which is %1.<br/><b>The sum of the funding amounts to %2.</b>',
            formatMoney(session.totalBudgetCents, session.totalBudgetCurrency),
            formatMoney(
              totalFundingCents,
              session.fundingItems[0].amountCurrency
            )
          );
        }
      }
    }
  })();

  return (
    <Box>
      <PullRight style={{ marginBottom: 8 }}>
        <SavingStatusLabel
          failedText={() => __('The training session could not be updated')}
        />
      </PullRight>
      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          trainingSessionId: session.id,
          type: 'training_session',
        })}
        onChange={persistSessionChanges}
        render={autoSavingOnChange => (
          <Fragment>
            <EditableFields
              session={session}
              onChange={autoSavingOnChange}
              errors={errors}
              disabled={cannotUpdateSession}
            />
            {!!warningMessage && (
              <Notification kind="warning" icon="warning">
                <Text preset="13s7" additionalClassName="block">
                  <StrictlySanitizedHtml html={warningMessage} />
                </Text>
              </Notification>
            )}
            {session.sameFundingPerParticipant && (
              <SessionFundingSection
                periodSlug={session.period.slug}
                fundingItems={session.fundingItems || []}
                errors={errors}
                disabled={cannotUpdateSession}
                onChange={fundingItems =>
                  autoSavingOnChange({
                    fundingItemsAttributes: fundingItems.map(item => ({
                      ...item,
                      fundingSourceId: item.fundingSource.id,
                    })),
                  })
                }
              />
            )}
          </Fragment>
        )}
      />
    </Box>
  );
};

export default Cost;
